
import { mapGetters } from 'vuex'
import { Currency } from '../../plugins/Currency'
import Types from '../../static/database/Types.json'
import { convertToSlug } from '@/helpers/generate-slug'
export default {
  props: {
    popular: {
      default: false,
      type: Boolean,
    },
    product: {
      default: () => {
        return {
          imagePath: '',
          price: 1,
          langCode: 'df',
          discount: 0,
          category: {
            name: '',
          },
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      getUserIP: 'user/getUserIP',
    }),
    type() {
      return Types.find((t) => t.type === this.product.type)
    },
  },
  methods: {
    convertToSlug,
    Currency(p) {
      return Currency(p, this.getUserIP.currency)
    },
    IS_FREE(price, discount) {
      const discountedPrice = (price * discount) / 100
      if (price - discountedPrice === 0) return true
      else false
    },
  },
}
